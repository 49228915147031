<template>
  <div class="recommend-detail">
    <div class="recommend-content">
        <div class="recommend-title">
            <img src="@src/assets/images/candidateDetail/recommend.png" alt="">
            <span class="tip">正在推荐{{candidateDetail.realName ? '候选人 ' + candidateDetail.realName + ' ' : '新候选人'}}到</span>
            <template v-if="jobDetail.name">
                <span class="job-name main" :title="`${jobDetail.name}[${jobDetail.location}]`">{{jobDetail.name}}[{{jobDetail.location}}]</span> - <span class="job-name sub">{{jobDetail.firmShortName}}</span>
            </template>
        </div>
        <p class="recommend-pm-tip" v-if="jobDetail.hasProjectManager">
            提示：本职位有专属PM，需PM初筛通过后，才推荐给HR反馈
        </p>
        <candidate-resume-view
            :candidateDetail="candidateDetail"
            :isComplete="isNeedEditBaseInfo"
            v-loading="candidateLoading"
            @edit-base-info="editBaseInfo"
        ></candidate-resume-view>
    </div>
    
    <div class="recommend-option">
        <!-- 职位来源 -->
        <div class="job-from">
            <div class="from-wrap">
                <span class="from-label">来源渠道</span>
                <el-radio-group v-model="jobFrom" @change="radioChange">
                    <el-radio 
                        v-for="(rItem, index) in jobFromArray"
                        :key="'jobFrom_' + index"
                        :label="rItem.value">
                        {{rItem.label}}
                    </el-radio>
                </el-radio-group>
                <div class="other-input-wrap" v-if="jobFrom == '其它'">
                    <el-input 
                        style="width: 180px; "
                        v-model.trim="otherContext" 
                        maxlength="10"
                        placeholder="最多10字">
                    </el-input>
                    <div class="others-tip" v-show="showOtherTip">请输入渠道来源</div>
                </div>
            </div>
            <div 
                class="from-tip" 
                v-show="showFromTip" 
                v-text="jobFromTipText">
            </div>
        </div>

        <!-- 业务分类 -->
        <div class="job-from business-classfication-form" v-if="isCFUser">
            <div class="from-wrap">
                <span class="from-label">业务分类</span>
                <el-select v-model="BusinessClassificationId" clearable placeholder="请选择目标业务分类" @change="changeSelectBusinessClass">
                  <el-option
                    v-for="item in businessClassificationList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
            </div>
            <div 
                class="from-tip" 
                v-show="showBusinessTip" 
                v-text="jobBusinessTipText">
            </div>
        </div>

        <!-- 推荐城市 -->
        <div class="job-from city-form">
            <div class="from-wrap">
                <span class="from-label">推荐城市</span>
                <div class="hot-city">
                    <span v-for="(city, key) in hotCityMap" :key="key" @click="selectHotCity(city.label, city.value)">{{city.label}}</span>
                </div>
                <el-input class="col-8" readOnly="true" @click.native="showCitySelect" placeholder="请选择，最多选择5项" :value="locationName"></el-input>
                <select-city-dialog ref="selectCityDialog" :has-primary-city="true" @city-selected="handleCitySelect"></select-city-dialog>
                
            </div>
            <div 
                class="from-tip" 
                v-show="showCityTip" 
                v-text="jobCityTipText">
            </div>
        </div>

        <!-- <candidate-recommend-form
          ref="recommendForm"
          class="candidate-recommend-form"
          v-if="!isRecommended && !candidateLoading"
          v-loading="recommendLoading"
          :candidateId="candidateDetail.id"
          :firmId="candidateDetail.firmId"
          @recommend-confirm="showRecommendConfirmDialog"
          @recommend-cancel="goToUrl(`/Headhunting/MyCompany.html#/Job/${jobId}`, false)"
          @recommend-end="recommendEnd"
        ></candidate-recommend-form> -->
        <candidate-recommend-form
          ref="recommendForm"
          class="candidate-recommend-form"
          v-if="!isRecommended && !candidateLoading"
          v-loading="recommendLoading"
          :candidateId="candidateDetail.id"
          :firmId="candidateDetail.firmId"
          @recommend-confirm="showRecommendConfirmDialog"
          @recommend-cancel="goToUrl(`/#/Job/${jobId}`, false)"
          @recommend-end="recommendEnd"
        ></candidate-recommend-form>
    </div>

    <div
      class="recommend-done-tip"
      v-if="isRecommended && !candidateLoading"
    >
      <p class="tip-context">
        {{recommendedMessage}}
      </p>
      <!-- <el-button @click="goToUrl(`/Headhunting/MyCompany.html#/Job/${jobId}`, false)">取消</el-button> -->
      <el-button @click="goToUrl(`/#/Job/${jobId}`, false)">取消</el-button>
    </div>
    <job-match-continue-dialog
      ref="jobMatchContinueDialog"
      :candidateName="candidateDetail.realName"
      :jobDetail="jobDetail"
    ></job-match-continue-dialog>
    <base-info-edit-dialog
      ref="baseInfoEditDialog"
      :isRecommend="true"
      @candidate-detail-refresh="getCandidateDetail"
    ></base-info-edit-dialog>
    <recommend-confirm-dialog
      ref="recommendConfirmDialog"
      :candidateDetail="candidateDetail"
      :jobDetail="jobDetail"
      @recommend-candidate="recommendCandidate"
      @recommend-cancel="recommendLoading = false;"
    ></recommend-confirm-dialog>
    <!-- <reject-reason-dialog ref="rejectReasonDialog"></reject-reason-dialog> -->
    <el-dialog
      ref="userInfoFillDialog"
      class="has-no-title"
      :visible.sync="userInfoFillDialogVisible"
      width="500px"
    >
      <div class="recommend-confirm">
          请到
          <span class="text-primary">【我的资料】</span>
          页面补充你的
          <span class="text-primary">【个人头像】</span>
          和
          <span class="text-primary">【职位】</span>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="userInfoFillDialogVisible = false">取消</el-button>
          <!-- <el-button type="primary" @click="goToUrl('/Headhunting/MyCompany.html#/personalCenter?Tab=BasicInfo', true)">去补充</el-button> -->
          <el-button type="primary" @click="goToUrl('/#/personalCenter?Tab=BasicInfo', true)">去补充</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { job as jobUrl, candidate as candidateUrl, recommend as recommendUrl, resume as resumeUrl } from '@src/js/config/api.json';
import CandidateResumeView from './layout/candidateResumeView.vue';
import CandidateRecommendForm from './layout/candidateRecommendForm.vue';
import JobMatchContinueDialog from './component/jobMatchContinueDialog.vue';
import BaseInfoEditDialog from '@src/component/common/dialog/baseInfoEditDialog.vue';
import RecommendConfirmDialog from './component/recommendConfirmDialog.vue';
import SelectCityDialog from '#/component/common/dialog/select-city-dialog.vue';
import hotCityMap from '#/js/config/hotCity.json';
// import RejectReasonDialog from './component/rejectReasonDialog.vue';
import businessClassificationService from '#/js/service/businessClassificationService.js';

export default {
  components: {
    CandidateResumeView,
    CandidateRecommendForm,
    JobMatchContinueDialog,
    BaseInfoEditDialog,
    RecommendConfirmDialog,
    SelectCityDialog,
    // RejectReasonDialog,
  },
  data() {
    return {
        BusinessClassificationId: '',
        businessClassificationList: [],
        curRecommendJobType: '', // 当前推荐的职位类型：3为HR直招
        jobId: "",
        candidateId: "",
        jobDetail: {},
        candidateDetail: {},
        resumeDetail: {},
        isComplete: true,
        isRecommended: false,
        recommendedMessage: "",
        candidateLoading: false,
        isNeedEditBaseInfo: false,
        recommendLoading: false,
        userInfoFillDialogVisible: false,
        recommendCandidateForm: {},

        // 职位渠道来源
        jobFrom: null,
        jobFromArray: [
            { label: '猎必得', value: '猎必得' },
            { label: '猎聘', value: '猎聘' },
            { label: '脉脉', value: '脉脉' },
            { label: '领英', value: '领英' },
            { label: '拉钩', value: '拉钩' },
            { label: 'BOSS', value: 'BOSS' },
            { label: '智联&51Job', value: '智联&51Job' },
            { label: '转介绍', value: '转介绍' },
            { label: 'Mapping', value: 'Mapping' },
            { label: '其它', value: '其它' },
        ],
        otherContext: '',
        jobFromTipText: '当前尚未添加简历来源渠道，请勾选后再进行推荐。', // 请输入渠道来源。
        showFromTip: false,
        jobBusinessTipText: '当前尚未添加业务分类，请选择后再进行推荐。', // 请选择业务分类
        showBusinessTip: false,
        jobCityTipText: '当前尚未添加简历推荐城市，请选择后再进行推荐。', // 请选择推荐城市。
        showCityTip: false,
        showOtherTip: true,
        hotCityMap: hotCityMap,
        locations: [],
        locationName: '',
        isRecommending: false
    }
  },
  created() {
    let url = window.location.href;
    let p = url.split('?')[1];
    let routeParams = new URLSearchParams(p);
    this.curRecommendJobType = routeParams.get('curRecommendJobType');
    // console.log(this.curRecommendJobType)
  },
  mounted() {
    this.jobId = this.$route.query.jobId;
    this.candidateId = this.$route.query.candidateId;
    if(this.isCFUser) {
      this.getBusinessClassificationList();
    }
    this.getJobDetail();
    this.getCandidateDetail();
    this.checkRecommendedResume();
    this.$refs.jobMatchContinueDialog.show(this.jobId, this.candidateId);
    // this.$refs.rejectReasonDialog.show(this.jobId);
  },
    watch: {
        otherContext(val){
            if(this.jobFrom == '其它') {
                this.showOtherTip = (val.trim() == '');
            }
        },
    },
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
        marketJobType() {
            if(this.$route.query.marketJobType == 'undefined') {
                return null;
            }
            return this.$route.query.marketJobType;
        },
    },
  methods: {
    // 获取业务分类列表
    getBusinessClassificationList() {
        let params = {
          start: 0,
          take: 100
        }
        businessClassificationService
            .getBusinessClassificationList(params).then(res => {
                // console.log(res);
                this.businessClassificationList = res.list;
            }).catch(err => {
                console.log(err);
            })
    },
    getJobDetail() {
      _request({
        url: jobUrl.url_job_info.replace(/\%p/ig, this.jobId),
        method: "GET"
      }).then(res => {
        this.jobDetail = res;
        document.title = `推荐候选人_${res.name}_${this.$store.state.user.userInfo.firmShortName}_猎必得_最有效的O2O猎头招聘平台`;
      }).catch(err => {});
    },
    getCandidateDetail() {
      this.candidateLoading = true;
      _request({
        url: `/ResumeDetail/${this.candidateId}`,
        method: "GET",
        data: {
          _: new Date().getTime()
        }
      }).then(res => {
        this.candidateDetail = res;
        this.candidateLoading = false;
        this.checkResumeComplete();
        if(res.tbdResumeId) {
          this.getTempRecommendReason(res.tbdResumeId);
       }
      }).catch(err => {});
    },
    checkRecommendedResume() {
      _request({
        url: recommendUrl.check_recommended_resume.replace(/\%p/ig, this.jobId),
        method: "POST",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          candidateId: this.candidateId
        },
        throwBusinessError: true
      }).then(res => {
        this.isRecommended = false;
      }).catch(err => {
        if(!err.succeeded) {
          this.recommendedMessage = err.message;
          this.isRecommended = true;
        }
      });
    },
    checkResumeComplete() {
      _request({
        url: candidateUrl.is_resume_complete.replace(/\%p/ig, this.candidateId),
        method: "POST",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        throwBusinessError: true
      }).then(res => {
        this.isNeedEditBaseInfo = false;
      }).catch(err => {
        if(!err.succeeded && err.data && err.data.length > 0) {
            this.isNeedEditBaseInfo = true;
            shortTips(err.data[0] && err.data[0].description);
        }
      });
    },
    getTempRecommendReason(resumeId) {
      _request({
        url: resumeUrl.resume_tempRecommendReason_get.replace(/\%p/g, resumeId),
        method: "GET"
      }).then(res => {
        if(res && res.reason) {
          this.$refs.recommendForm.recommendReasonForm.RecommendReason = res.reason;
        }
      });
    },
    editBaseInfo() {
        this.$refs.baseInfoEditDialog.show({...this.candidateDetail, fromRecommendDetail: true});
    },
    showRecommendConfirmDialog(params) {
      this.recommendCandidateForm = Object.assign({
        candidateId: this.candidateId,
        __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
      }, params);
      this.recommendLoading = true;
      this.$refs.recommendConfirmDialog.show(this.curRecommendJobType);
    },
    radioChange() {
      this.showFromTip = false;
    },
    changeSelectBusinessClass(val) {
      if(val) {
        this.showBusinessTip = false;
      }
    },
    selectHotCity(name, code) {
      if(this.locations.length == 5) {
        shortTips('最多选择五项地区！');
        return false;
      }
      if(this.locations.indexOf(code) > -1) {
        return false;
      }
      this.locations.push(code);
      switch(name) {
        case '北':
          name = '北京';
          break;
        case '上':
          name = '上海';
          break;
        case '广': 
          name = '广州';
          break;
        case '深':
          name = '深圳';
          break;
        default: 
          break;
      }

      if(this.locationName) {
        this.locationName = `${this.locationName},${name}`;
      }else {
        this.locationName = name;
      }

      this.showCityTip = false;
    },
    showCitySelect() {
      this.$refs.selectCityDialog.show();
      this.$nextTick(() => {
        if(this.$refs.selectCityDialog.$refs.provinceCity) {
          this.$refs.selectCityDialog.$refs.provinceCity.clearSelected();
          this.$refs.selectCityDialog.$refs.provinceCity.setCheckedCityText(this.locations);
            // this.form.locations.forEach(code => {
            //     this.$refs.selectCityDialog.$refs.provinceCity.selectCityOutside(code);
            // })
        }
      })
    },
    handleCitySelect() {
      let selected = this.$refs.selectCityDialog.selectedCity;
      let target = [];
      let targetNames = [];
      selected.forEach(item => {
          target.push(item.id);
          targetNames.push(item.text);
      })
      this.locations = target;
      this.locationName = targetNames.join(',');
      this.showCityTip = false;
    },
    // 确定推荐
    recommendCandidate() {
        if (this.isRecommending) {
          return false;
        }
        this.isRecommending = true;
        const fileList = this.$refs.recommendForm._data.fileList;

        let formData = new FormData();
        for(const itemKey in this.recommendCandidateForm) {
            formData.append(itemKey, this.recommendCandidateForm[itemKey]);
        }
        if(!['3', '5'].includes(this.marketJobType)) {
            // formData.append(`UploadToResumeAttachment`, !this.$refs.recommendForm.showAttach);
            fileList.forEach(el => {
                if (el.raw) {
                    formData.append(`RecommendAttachment`, el.raw);
                }
            });
        } 
        // else{
        //     formData.append(`UploadToResumeAttachment`, false);
        // }
        
        const _jobFrom = this.jobFrom == '其它' ? this.otherContext : this.jobFrom;
        formData.append(`candidateSourceChannel`, _jobFrom);
        formData.append('location', this.locations[0]);
        this.locations.forEach(location => {
          formData.append('locations[]', location);
        });
        // formData.forEach((value, key) => {
        //     console.log("key %s: value %s", key, value);
        // });
        const _viewLink = `${_host.frontSite}/#/Job/${this.jobId}/recommendation/{recommendationId}`;
        formData.append('viewLink', _viewLink);

        if(this.isCFUser) {
          formData.append(`BusinessClassificationId`, this.BusinessClassificationId);
        }
        console.log(formData)

        let _url;
        if(this.marketJobType == 3) {
          _url = recommendUrl.hr_job_recommend.replace(/\%p/g, this.jobId);
        } else {
          _url = recommendUrl.job_recommend.replace(/\%p/g, this.jobId);
        }

        _request({
            url: _url,
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData,
            throwBusinessError: true
        }).then(res => {
            if(['3'].includes(this.marketJobType)) {
                shortTips(`推荐申请已提交，请耐心等待审批~`);
                setTimeout(() => {
                    location.href = `/Headhunting/#/floatingList/recommendAudit`;
                }, 1500);
            } else {
                sessionStorage.setItem('recommendReason', this.$refs.recommendForm.recommendReasonForm.RecommendReason);
                sessionStorage.setItem('recommendationId', res?.recommendationId);
                sessionStorage.setItem('recommendShareCode', res.code);
            // this.$refs.recommendForm.submitUpload(res.recommendationId);
                this.recommendEnd();
            }
        }).catch(err => {
            let errorMsg = [];
            if(err.data) {
                Object.keys(err.data).forEach(key => {
                    if(err.data[key].length > 0) {
                    errorMsg.push(err.data[key][0]);
                    }
                });
            }
            if(err.message) {
                if(err.message.indexOf('请先补充个人信息！') > -1) {
                    this.userInfoFillDialogVisible = true;
                } else {
                    errorMsg.push(err.message);
                }
            }
            if(errorMsg.length > 0) {
                shortTips(errorMsg.join("，"));
            }
            this.recommendLoading = false;
        }).finally(() => {
          this.isRecommending = false;
        });
    },
    recommendEnd() {
        this.recommendLoading = false;
        let queryObj = this.$route.query,
            queryArr = [];
        Object.keys(queryObj).forEach(key => {
            if(key == 'jobId') {
                queryArr.push(`recommendJobId=${queryObj[key]}`);
            } else {
                queryArr.push(`${key}=${queryObj[key]}`);
            }
        });
        // location.href = `/Headhunting/MyCompany.html#/resultRecommend?${queryArr.join('&')}`
        location.href = `/#/resultRecommend?${queryArr.join('&')}`
    },
    goToUrl(url, isBlank) {
      if(isBlank) {
        window.open(url);
      } else {
        location.href = url;
      }
    }
  }
}
</script>

<style lang="scss" scope>
.text-primary {
  color: $primary;
}
.recommend-detail {
  // width: 1200px;
  // margin: 60px auto;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
    .recommend-content {
        width: 1200px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 8px;

        .recommend-title {
          height: 78px;
          line-height: 78px;
          color: #333;
          padding: 0 30px;
          border: 1px solid #eee;
          font-size: 16px;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            flex-shrink: 0;
          }
          .tip {
            flex-shrink: 0;
          }
          .job-name {
            color: $primary;

            &.main {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.sub {
              flex-shrink: 0;
            }
          }
        }
        .recommend-pm-tip {
          margin-bottom: 20px;
          padding: 15px;
          border-radius: 4px;
          color: #3c763d;
          background-color: #dff0d8;
          line-height: 24px;
        }
    }
    .recommend-option {
      width: 1200px;
      padding: 0 30px 40px;
      margin: 20px auto 0;
      background-color: #fff;
      border-radius: 8px;
    }
    .job-from{
        // background: #f5fbfa;
        // margin: 22px 0;
        box-sizing: border-box;
        padding: 20px 0;

        &.business-classfication-form {
          .el-select {
            width: 300px;
          }
        }

        &.city-form {
          position: relative;
          padding-bottom: 40px;

          .from-tip {
            position: relative;
            top: 30px;
          }
        }

        &.city-form .from-wrap {
          display: flex;
          align-items: center;

          .hot-city {
            position: absolute;
            top: 60px;
            left: 108px;
            @include clearfix();

            span {
              display: inline-block;
              padding: 0 4px;
              color: $primary;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }

        .from-wrap{
            width: 100%;
            // height: 42px;
            line-height: 42px;
            .from-label{
                color: #222;
                font-size: 18px;
                margin-right: 10px;
                flex-shrink: 0;
            }
            .el-radio{
                margin-right: 20px;
            }
            .other-input-wrap{
                display: inline-block;
                vertical-align: top;
                .others-tip{
                    display: block;
                    color: red;
                    line-height: 18px;
                    font-size: 12px;
                    // padding-left: 20px;
                    box-sizing: border-box;
                }
            }
        }
        .from-tip{
            color: red;
            line-height: 24px;
            font-size: 12px;
        }
    }
  .candidate-recommend-form {
    margin-top: 16px;
  }
  .recommend-done-tip {
    margin-top: 30px;
    .tip-context {
      margin-bottom: 20px;
      background-color: #f5fbfa;
      padding: 30px 20px;
      color: #FF6564;
      line-height: 24px;
    }
  }
}
</style>