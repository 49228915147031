var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-resume-view" }, [
    _c("div", { staticClass: "resume-view-title" }, [
      _c("span", [_vm._v("\n            简历预览\n        ")]),
      !_vm.candidateDetail.isFromAuthorization
        ? _c("span", { staticClass: "resume-view-title-tip" }, [
            _vm.$store.state.user.userInfo.isCFUser
              ? _c("i", { staticClass: "point-tip" }, [
                  _vm._v(
                    "更新简历有奖哦，新增候选人最新雇主公司和职位可得15积分"
                  ),
                ])
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "text-operate-btn",
                attrs: {
                  href: `/Headhunting/MyCompany.html?type=1&candidateId=${_vm.candidateDetail.id}#/resumeUpload/singleUpload`,
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "el-icon-edit-outline" }),
                _vm._v("\n                编辑\n            "),
              ]
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "resume-view-container" }, [
      _c("div", { staticClass: "resume-base-info" }, [
        _c("h3", { staticClass: "candidate-realname" }, [
          _vm._v(_vm._s(_vm.candidateDetail.realName)),
        ]),
        _c("p", { staticClass: "candidate-info" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.candidateDetail.company) +
              " " +
              _vm._s(_vm.candidateDetail.title) +
              "\n            "
          ),
        ]),
        _c("p", { staticClass: "candidate-info" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.candidateDetail.degreeInfo) +
              "\n                "
          ),
          _vm.candidateDetail.degreeInfo && _vm.candidateDetail.yearOfExperience
            ? _c("span", [_vm._v("|")])
            : _vm._e(),
          _vm._v(
            "\n                " +
              _vm._s(_vm.candidateDetail.yearOfExperience) +
              "\n            "
          ),
        ]),
        _c(
          "div",
          { staticClass: "candidate-info info-with-icon" },
          [
            _c("font-icon", { attrs: { href: "#icon-message" } }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.candidateDetail.email
                    ? _vm.candidateDetail.email
                    : "******"
                ) +
                "\n                "
            ),
            _c("font-icon", { attrs: { href: "#icon-mobile" } }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.candidateDetail.mobile
                    ? _vm.candidateDetail.mobile
                    : "******"
                ) +
                "\n                "
            ),
            _vm.candidateDetail.provinceName || _vm.candidateDetail.cityName
              ? [
                  _c("font-icon", { attrs: { href: "#icon-address-copy" } }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.candidateDetail.provinceName) +
                      " " +
                      _vm._s(_vm.candidateDetail.cityName) +
                      "\n                "
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._m(0),
        _vm.isComplete && !_vm.candidateDetail.isFromAuthorization
          ? _c(
              "div",
              { staticClass: "base-info-tip" },
              [
                _c("span", [_vm._v("候选人基本信息不完整，请补充")]),
                _c(
                  "el-button",
                  {
                    staticClass: "complete-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.editBaseInfo },
                  },
                  [_vm._v("编辑")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "resume-content" }, [
        _c("h4", { staticClass: "resume-content-title" }, [_vm._v("个人简历")]),
        _c("div", {
          staticClass: "resume-detail",
          domProps: {
            innerHTML: _vm._s(_vm.changeContent(_vm.candidateDetail.content)),
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "candidate-info view-tip" }, [
      _c("span", { staticClass: "view-tip-icon" }, [_vm._v("匿")]),
      _vm._v("手机和电话会被隐藏，安排面试后HR才能看见\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }